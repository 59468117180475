import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { pageMetadataMap, PageType } from '@/shared/app-common';
import Image from 'next/image';
import { HomePreviewDataModel } from '@/types/home';

const LoginStateInfo = ({ pageType, preViewData }: { pageType?: PageType, preViewData?: HomePreviewDataModel }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const pageMetaData = pageMetadataMap[pageType ?? PageType.Normal];

  const list = useMemo(() => {
    return [
      t(`<p>Trusted by <span style='color:#725CFF'>15,000+</span> Users</p>`),
      t(`<p>Rich <span style='color:#725CFF'>Data</span></p>`),
      t(`<p><span style='color:#725CFF'>AI</span>-Powered</p>`),
      t(`<p>100% <span style='color:#725CFF'>Anonymous</span></p>`),
    ]
  }, []);
  const onDashboardClick = () => {
    router.push(`/dashboard?alertType=1`);
    ga_logEvent(EventName.home_ADD_ACCOUNT);
  };

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h1>
          {preViewData?.topInfo?.title ?? ` ${t(pageMetaData.title1Key)} `}
        </h1>

        <h2>
          {preViewData?.topInfo?.subTitle ?? ` ${t("Your All-in-One Tool to Track, View, and Download with Ease")} `}
        </h2>

        <p><span style={{ color: 'var(--app-text-color)', fontWeight: '600' }}>{preViewData?.topInfo?.desc1 ?? t(pageMetaData.title2Key)} </span> {preViewData?.topInfo?.desc2 ?? t(pageMetaData.titleDescKey)}</p>

        <div className={styles.list}>
          {(preViewData?.topInfo?.subTitleList ?? list).map((item, index) => {
            return <div key={index} className={styles.listItem}>
              <Image src={'/images/home/home-select-icon.webp'} alt='' width={17} height={12} />
              <p dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          })}

        </div>

        <div className={styles.btnContainer}>
          <Link className={styles.seeAnalyticsSample} href="/instagram-tracking-example">
            {preViewData?.topInfo?.btnTitle1 ?? t('SeeAnalyticsSample')}
          </Link>
          <span className={styles.goToDashboard} onClick={onDashboardClick}>
            {preViewData?.topInfo?.btnTitle2 ?? t('ADD ACCOUNT')}
          </span>
        </div>

      </div>
    );
  }, [pageMetaData, onDashboardClick, preViewData]);

  return <>{getContent}</>;
};

export default LoginStateInfo;

import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { AppSettings, commonImgPath } from '@/shared/app-common';

import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Carousel } from 'antd';
import styled from 'styled-components';
import Image from 'next/image';
// import { imgUrl } from '@/shared/utils';
import { HomePreviewDataModel } from '@/types/home';
import { useWindowSize } from 'react-use';

export const CustomCarousel = styled(Carousel)`
  .slick-dots li.slick-active button {
    background-color: #725CFF; /* 设置当前指示点的颜色 */
  }

  .slick-dots li button {
    background-color: #D9D9D9; /* 设置未激活指示点的颜色 */
  }
`;

const Card = ({
  title,
  desc,
  imgPath,
  link,
  isRight,
  isMobile = false,
}: {
  title?: string;
  desc?: string;
  imgPath?: string;
  link?: string;
  isRight?: boolean;
  isMobile?: boolean;
}) => {
  const router = useRouter();
  return (
    <div
      onClick={() => link && router.push(link)}
      className={styles.card}
      style={{ backgroundImage: `url(${imgPath})` }}
    >
      <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p
        className={styles.desc}
        style={{ width: isMobile ? '80%' : (isRight ? '55%' : '45%') }}
        dangerouslySetInnerHTML={{ __html: desc }}
      />
    </div>
  );
};

const MemoizedCard = memo(Card);

const DetailShowContent = ({ preViewData }: { preViewData?: HomePreviewDataModel }) => {
  const { t } = useTranslation('common');
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const items = useMemo(() => {
    if (preViewData?.meetsInfo?.dataList?.length > 0) return preViewData?.meetsInfo?.dataList;

    return [
      {
        title: t("Curious about your loved ones' Instagram activity?"),
        desc: t(
          "With Dolphin Radar, you can view their interactions, likes, followers, and recent follow activity anonymously, keeping your curiosity private while staying informed.",
        ),
        imgPath: commonImgPath + '/f47ac10b-58cc-4372-a567-0e02b2c3d479.webp',
        link: `/view-instagram-anonymously`,
      },
      {
        title: t("Stay on Top of Your Competition"),
        desc: t(
          "Want to track your competitors' every move on Instagram? Monitor their follows, likes, and engagement. Stay one step ahead of your competition. Make smarter decisions and gain the competitive edge!",
        ),
        imgPath: commonImgPath + '/c9bf9e57-1685-4c89-bafb-ff5af830be8a.webp',
        link: `/instagram-tracker`,
      },
      {
        title: t("Anonymous Instagram Viewing Made Easy"),
        desc: t(
          "Download Instagram Stories and Highlights discreetly with Dolphin Radar. No need to log in—your identity stays hidden while you access the content you want."
        ),
        imgPath: commonImgPath + '/6ba7b810-9dad-11d1-80b4-00c04fd430c8.webp',
        isRight: true,
        link: `/download-from-instagram`,
      },
      {
        title: t('Monitor Suspicious Instagram Behavior'),
        desc: t(
          `Identify unusual Instagram behavior like sudden follower spikes or cheating signs. Dolphin Radar helps you stay aware while staying private.`
        ),
        imgPath: commonImgPath + '/550e8400-e29b-41d4-a716-446655440000.webp',
        isRight: true,
        link: `/instagram-influencer-trends`,
      },
      {
        title: t('Uncover Instagram Influencer Gossip'),
        desc: t(`Observe Instagram influencers' behaviors to discover their routines, interests, and interactions, uncovering the latest gossip and helping you stay connected to their world.`),
        imgPath: commonImgPath + '/1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed.webp',
        isRight: true,
        link: `/catch-instagram-cheaters`,
      },
    ]
  }, [preViewData]);

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h2>{preViewData?.meetsInfo?.title ?? t('WhyDoYouNeedDolphinRadar')}</h2>

        {preViewData?.meetsInfo?.desc?.length > 0 ? <div className={styles.text}>
          <p>{preViewData?.meetsInfo?.desc}</p>
        </div> : <div className={styles.text}>
          <p>{t('Are you curious about those special Instagram accounts on your watchlist?')} {t('WhyDoYouNeedDolphinRadar_Desc')}</p>
        </div>}

        <div ref={divRef} className={styles.topImageContainer}>
          {isVisible && <Image
            src={preViewData?.meetsInfo?.imgPath ?? (commonImgPath + '/home_detail_show_top_bg_3.webp')}
            alt={'detail_show'}
            layout="responsive"
            width={300}
            height={400}
            className={styles.topImage}
          />}
        </div>

        {typeof window !== 'undefined' && <>{
          width > 900 ? (
            <div className={classNames([styles.bottomContainerPC])}>
              <div className={styles.leftSubContainer}>
                {items.slice(0, 2).map((item, index) => (
                  <MemoizedCard key={index} {...item} />
                ))}
              </div>

              <div className={styles.leftSubContainer}>
                {items.slice(2).map((item, index) => (
                  <MemoizedCard key={index} {...item} />
                ))}
              </div>
            </div>
          ) : (
            <div className={classNames([styles.bottomContainerMobile])}>
              <CustomCarousel lazyLoad="progressive">
                {items.map((item, index) => (
                  <MemoizedCard key={index} {...item} isMobile={true} />
                ))}
              </CustomCarousel>
            </div>
          )
        }</>}
      </div>
    );
  }, [items, preViewData, isVisible, width]);

  return <>
    {getContent}
  </>;
};

export default DetailShowContent;
